import React, { useState, useEffect } from "react";

const DashboardPage = () => {
  // Mock data for orders
  const [orders, setOrders] = useState([
    {
      id: 1,
      name: "Rajeev Tewatia",
      productName: "QR Code CAR",
      price: 200,
      promoCode: "TEJAS",
      date: "2024-12-29",
    },
    {
      id: 2,
      name: "Vikram Singh",
      productName: "QR Code BIKE",
      price: 300,
      promoCode: "None",
      date: "2024-12-28",
    },
    {
      id: 3,
      name: "Rishabh Arora",
      productName: "QR Code CAR",
      price: 200,
      promoCode: "TEJAS",
      date: "2024-12-27",
    },
  ]);

  const [metrics, setMetrics] = useState({
    totalOrders: 0,
    totalRevenue: 0,
    promoUsage: {},
  });

  useEffect(() => {
    // Calculate metrics
    const totalOrders = orders.length;
    const totalRevenue = orders.reduce((sum, order) => sum + order.price, 0);
    const promoUsage = orders.reduce((acc, order) => {
      const promo = order.promoCode || "None";
      acc[promo] = (acc[promo] || 0) + 1;
      return acc;
    }, {});

    setMetrics({ totalOrders, totalRevenue, promoUsage });
  }, [orders]);

  return (
    <section className="bg-gray-50 min-h-screen py-10 px-4 sm:px-6 lg:px-10">
      <div className="max-w-7xl mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Dashboard</h1>

        {/* Metrics Section */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold text-gray-700">
              Total Orders
            </h2>
            <p className="text-2xl font-bold text-blue-600">
              {metrics.totalOrders}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold text-gray-700">
              Total Revenue
            </h2>
            <p className="text-2xl font-bold text-green-600">
              Rs {metrics.totalRevenue}
            </p>
          </div>
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-semibold text-gray-700">Promo Usage</h2>
            <ul>
              {Object.entries(metrics.promoUsage).map(([promo, count]) => (
                <li key={promo} className="text-lg text-gray-600">
                  {promo}: {count} {count > 1 ? "times" : "time"}
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Orders Table */}
        <div className="bg-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold text-gray-800 mb-4">Orders</h2>
          <div className="overflow-x-auto">
            <table className="min-w-full table-auto">
              <thead>
                <tr className="bg-gray-200 text-gray-700">
                  <th className="px-4 py-2 text-left">Order ID</th>
                  <th className="px-4 py-2 text-left">Customer Name</th>
                  <th className="px-4 py-2 text-left">Product</th>
                  <th className="px-4 py-2 text-left">Price (Rs)</th>
                  <th className="px-4 py-2 text-left">Promo Code</th>
                  <th className="px-4 py-2 text-left">Date</th>
                </tr>
              </thead>
              <tbody>
                {orders.map((order) => (
                  <tr
                    key={order.id}
                    className="border-b hover:bg-gray-100 text-gray-700"
                  >
                    <td className="px-4 py-2">{order.id}</td>
                    <td className="px-4 py-2">{order.name}</td>
                    <td className="px-4 py-2">{order.productName}</td>
                    <td className="px-4 py-2">Rs {order.price}</td>
                    <td className="px-4 py-2">{order.promoCode}</td>
                    <td className="px-4 py-2">{order.date}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DashboardPage;
