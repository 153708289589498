import React from "react";
import { useLocation } from "react-router-dom";

const PaymentPage = () => {
  const location = useLocation();
  const { formData } = location.state || {};

  if (!formData) {
    return (
      <p className="text-center text-red-600 mt-10">
        Missing checkout details. Please go back to the checkout page.
      </p>
    );
  }

  return (
    <section className="bg-gray-50 text-gray-800 py-10 px-4 sm:px-6 lg:px-10">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold mb-6 text-center">Payment</h1>
        <p className="text-gray-700 mb-4">
          <strong>Name:</strong> {formData.name}
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Phone:</strong> {formData.phone}
        </p>
        <p className="text-gray-700 mb-4">
          <strong>Address:</strong> {formData.address}, {formData.city} -{" "}
          {formData.postalCode}
        </p>
        <p className="text-gray-700 mb-6">
          <strong>Email:</strong> {formData.email}
        </p>

        <button
          className="w-full bg-green-600 text-white py-3 rounded-lg text-lg font-semibold hover:bg-green-700"
          onClick={() => alert("Redirecting to payment gateway...")}
        >
          Pay Now
        </button>
      </div>
    </section>
  );
};

export default PaymentPage;
