import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { servicesData } from "../assets/servicesData";

const ServiceDetailPage = () => {
  const { serviceId } = useParams();
  const service = servicesData.find((s) => s.id === serviceId);
  const [isModalOpen, setModalOpen] = useState(false);
  const [promoCode, setPromoCode] = useState("");
  const [discountedPrice, setDiscountedPrice] = useState(250);
  const [promoApplied, setPromoApplied] = useState(false);
  const [promoMessage, setPromoMessage] = useState("");

  const [selectedImage, setSelectedImage] = useState(service.icon);
  const navigate = useNavigate();
  if (!service) {
    return <p>Service not found</p>;
  }

  const handleApplyPromo = () => {
    if (promoCode === "TEJAS") {
      setPromoApplied(true);
      setDiscountedPrice(200); // Apply discount
      setPromoMessage("Promo 'TEJAS' applied. Rs 50 discount!");
      setTimeout(() => setPromoApplied(false), 1000); // Clear celebration effect
    } else {
      setPromoMessage("Invalid Promo Code. Try again.");
    }
  };

  const handleWhatsAppClick = () => {
    const message = `Hello, I am interested in the service ${service.name}. Can you provide more details?`;
    window.open(
      `https://api.whatsapp.com/send?phone=1234567890&text=${encodeURIComponent(
        message
      )}`,
      "_blank"
    );
  };
  const handleBuyNow = () => {
    navigate("/checkout", {
      state: {
        productName: service.name,
        productPrice: discountedPrice,
      },
    });
  };
  return (
    <section className="bg-gray-50 text-gray-800 py-10 px-4 sm:px-6 lg:px-10">
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Left Section - Product Images */}
        <div>
          <div className="border rounded-lg overflow-hidden shadow-lg">
            <img
              src={selectedImage}
              alt={service.name}
              className="w-full h-64 sm:h-96 object-cover"
            />
          </div>
          <div className="grid grid-cols-3 gap-4 mt-4">
            {service.realPhotos.slice(0, 3).map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`${service.name} example ${index + 1}`}
                className="w-full h-20 sm:h-24 object-cover rounded-lg shadow-md cursor-pointer"
                onClick={() => setSelectedImage(photo)}
              />
            ))}
          </div>
        </div>

        {/* Right Section - Product Details */}
        <div className="bg-white p-6 rounded-lg shadow-lg relative">
          {promoApplied && (
            <div className="absolute top-0 left-0 w-full h-full bg-green-100 flex items-center justify-center z-10 rounded-lg animate-bounce">
              <p className="text-green-700 font-bold text-lg">{promoMessage}</p>
            </div>
          )}
          <h1 className="text-2xl sm:text-4xl font-bold mb-4">
            {service.name}
          </h1>
          <p className="text-sm sm:text-lg text-gray-600 mb-6">
            {service.description}
          </p>

          {/* Pricing Section */}
          <div className="mb-6">
            <p className="text-xl sm:text-2xl font-bold mb-2">
              <span className="line-through text-gray-400 mr-2">500 Rs</span>
              <span className="text-green-600">{discountedPrice} Rs</span>
            </p>
            {promoMessage && (
              <p className="text-sm text-green-600 font-semibold mb-2">
                {promoMessage}
              </p>
            )}
            <div className="flex flex-wrap items-center gap-4">
              <input
                type="text"
                value={promoCode}
                onChange={(e) => setPromoCode(e.target.value)}
                placeholder="Enter Promo Code"
                className="border p-2 rounded-lg w-full sm:w-2/3"
              />
              <button
                onClick={handleApplyPromo}
                className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700"
              >
                Apply
              </button>
            </div>
          </div>

          {/* Buy Now Section */}
          <button
            className="w-full bg-blue-600 text-white py-3 rounded-lg text-lg font-semibold hover:bg-blue-700 mb-4"
            onClick={() => handleBuyNow()}
          >
            Buy Now
          </button>

          {/* WhatsApp Section */}
          <button
            className="w-full bg-red-600 text-white py-3 rounded-lg text-lg font-semibold hover:bg-green-700"
            onClick={handleWhatsAppClick}
          >
            Watch Demo on Youtube
          </button>

          {/* Specifications */}
          <div className="mt-8">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4">
              Specifications
            </h2>
            <ul className="list-disc ml-6 text-gray-600">
              <li>High-quality QR Code Scanner</li>
              <li>Seamless and efficient performance</li>
              <li>Trusted by thousands of users</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="mt-12 max-w-6xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-xl sm:text-2xl font-semibold mb-4">
          Customer Reviews
        </h2>
        <p className="text-gray-600 mb-4">
          ⭐️⭐️⭐️⭐️⭐️ - Excellent product! Highly recommend.
        </p>
        <p className="text-gray-600 mb-4">
          ⭐️⭐️⭐️⭐️ - Good value for money.
        </p>
        <p className="text-gray-600 mb-4">
          ⭐️⭐️⭐️⭐️ - Works perfectly for my needs.
        </p>
      </div>

      {/* FAQs */}
      <div className="mt-12 max-w-6xl mx-auto bg-gray-100 p-6 rounded-lg">
        <h2 className="text-xl sm:text-2xl font-semibold mb-4">
          Frequently Asked Questions
        </h2>
        <div>
          <p className="font-semibold text-gray-800">
            Q: How does this service work?
          </p>
          <p className="text-gray-600 mb-4">
            A: This service provides a unique QR code that links to your service
            details.
          </p>
          <p className="font-semibold text-gray-800">
            Q: Can I use this for my business?
          </p>
          <p className="text-gray-600 mb-4">
            A: Absolutely! It’s designed for businesses and individuals alike.
          </p>
        </div>
      </div>
    </section>
  );
};

export default ServiceDetailPage;
